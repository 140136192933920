.data-plotter {
  position: relative;
  path,
  line {
    stroke: $ms-color-gray160;
    stroke-width: 1;
  }
  text {
    color: $ms-color-gray160;
  }

  .formation-text {
    color: $ms-color-gray130;
    font-size: 12px;
  }

  .grid .tick {
    stroke-width: 1;
    line {
      stroke: $ms-color-gray60;
    }
  }

  .data-container {
    circle:hover,
    rect:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .current-depth-line {
    stroke: $ms-color-gray90;
    stroke-width: 1;
    stroke-dasharray: 4;
  }

  .highlighted-depth-line {
    stroke: #a4262c;
    stroke-width: 1;
    stroke-dasharray: 4;
  }
}

.range-slider-container {
  display: flex;
  position: absolute;
  width: 100%;
  align-content: space-between;
  height: 24px;
  padding: 0px 6px;
  bottom: 4px;

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.data-plotter__sidebar {
  border-left: 1px solid $ms-color-gray60;
  border-right: 1px solid $ms-color-gray60;
  background: $ms-color-gray30;

  &__separator {
    height: 1px;
    background-color: $ms-color-gray40;
  }
}

.plot-legend {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 24px 24px;
    font-size: 20px;
    font-weight: 600;
    color: $ms-color-gray190;
  }

  &__plot-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
  }

  &__plot {
    &__title {
      font-size: 14px;
      font-weight: 600;
      color: $ms-color-gray130;
      margin: 0 24px;
    }

    &__tab {
      font-size: 16px;
      font-weight: 600;
      color: $ms-color-gray160;
      margin: 14px 32px 8px;
    }

    .plot-legend-element-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin: 8px 32px;

      &__item {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 8px;

        &__color {
          width: 48px;
          height: 32px;
          // background set via inline styles
        }

        &__element {
          font-size: 14px;
          font-weight: 600;
          color: $ms-color-gray190;
          margin-left: 12px;
        }
      }
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: $ms-color-gray40;
      margin: 16px 0;
    }
  }
}
