$elementGroupColors: (
  carbonate-total: rgb(153, 51, 255),
  illite-total: rgb(0, 192, 0),
  smectite-total: rgb(245, 222, 179),
  mica-total: rgb(0, 100, 0),
  kaolin-total: rgb(210, 105, 30),
  sulphate-total: rgb(51, 204, 255),
  hc-total: rgb(0, 128, 128),
  hc-pixel-total: rgb(0, 170, 170),
  kfeldspar: rgb(0, 128, 128),
  andesine-anorthite: rgb(0, 192, 192),
  biotite: rgb(160, 82, 45),
  muscovite: rgb(0, 100, 0),
  illite: rgb(0, 192, 0),
  chlorite: rgb(171, 171, 0),
  smectite: rgb(245, 222, 179),
  otherclays: rgb(107, 142, 35),
  ankerite: rgb(128, 92, 255),
  siderite: rgb(181, 119, 121),
  feoxides: rgb(128, 0, 0),
  barite: rgb(128, 64, 128),
  halite: rgb(255, 192, 192),
  other-phases: rgb(211, 211, 211),
  unclassified: rgb(50, 50, 50),
);

.analytics-pane {
  position: absolute;
  top: $scaleBarWidth;
  right: 1px;
  z-index: 40;
  background-color: $ms-color-gray30;
  box-shadow: $ms-depth-shadow-16;
  user-select: none;

  &__overflow-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }

  &__tab {
    display: none;
    &--show {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
  }

  &__header-container {
    margin-top: 28px;
    margin-bottom: 24px;
  }

  &__heading {
    font-size: 24px;
    font-weight: 600;
    color: $ms-color-gray160;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__selection-zone {
    flex-grow: 1;
  }

  &__group-heading {
    font-size: 16px;
    font-weight: 600;
    color: $ms-color-gray160;
    margin: 0;
    &--spaced {
      margin-top: 32px;
      margin-bottom: 8px;
    }
  }

  &__group-devider {
    &--highlighted {
      background-color: $ms-color-gray40;
    }

    @each $groupName, $rgb in $elementGroupColors {
      &--#{$groupName} {
        .ms-GroupHeader-check[aria-checked="true"] {
          background: $rgb;
        }
      }
    }
  }

  &__basic-list {
    margin-left: 8px;
    margin-right: 8px;
    color: $ms-color-gray160;
  }

  &--horizontal-margin {
    margin-left: 16px;
    margin-right: 16px;
  }
  &--horizontal-margin-2 {
    margin-left: 24px;
    margin-right: 24px;
  }

  &__resize-handle {
    position: absolute;
    width: 8px;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-50%);
    cursor: col-resize;
    z-index: 40;
    outline: 0;

    .resize-thumb {
      opacity: 0;
      transition: opacity 150ms linear;
      transition-delay: 2s;
      z-index: 40;
    }

    .width-percentage {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 6px;
      transform: translateX(calc(-100% - 6px));
      width: 65px;
      height: 32px;
      font-size: 16px;
      color: $ms-color-gray160;
      background-color: $ms-color-gray30;
      user-select: none;
      border-radius: 2px;
      box-shadow: $ms-depth-shadow-16;
      transition: opacity 150ms linear;
      transition-delay: 2s;
      opacity: 0;

      &::after {
        position: absolute;
        right: 0;
        top: 8px;
        z-index: -1;
        height: 16px;
        width: 16px;
        content: "";
        transform: translateX(50%) rotate(45deg);
        background: $ms-color-gray30;
      }
    }

    &:hover {
      .resize-thumb,
      .width-percentage {
        transition-delay: 250ms;
        opacity: 1;
      }
    }
  }

  &__plotter {
    background: $ms-color-gray10;
    position: relative;
  }

  &__filtered-message {
    color: $ms-color-gray190;
    font-size: 14px;
    line-height: 20px;
  }

  &__filtered-message-header {
    font-size: 16px;
    font-weight: 600;
    color: $ms-color-gray160;
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.plotter-container {
  display: flex;
  flex-flow: row nowrap;
  padding-right: 3px;

  &__item {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    overflow: hidden;
    background: $ms-color-gray20;
    transition: background 150ms linear;
    outline: 1px solid $ms-color-gray60;
    outline-offset: -1px;

    &:hover {
      background: $ms-color-gray10;
    }

    &--highlighted {
      background: $ms-color-gray10;
    }

    &--outlined {
      outline: 2px solid $theme-primary;
      outline-offset: -2px;
    }

    &--clickable {
      cursor: pointer;
    }

    &__units {
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
    }
  }
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
}
