.resize-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 16px;
  height: 28px;
  background: $ms-color-gray40;
  border: 1px solid $ms-color-gray130;
  border-radius: 2px;
  cursor: col-resize;
  filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)),
    drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13));
  &:hover {
    background: $ms-color-gray50;
    filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)),
      drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13));
  }
}
