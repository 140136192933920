.map-view-menu {
  display: inline-flex;
  background: $ms-color-gray30;
  pointer-events: all;
  filter: $ms-depth-shadow-16;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 20px;
}

.wellbore-filters {
  margin: 0 8px;
  background-color: $ms-color-gray30;
  width: 280px;
  height: 100%;

  &__label {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 8px;
    span:nth-child(1) {
      flex-grow: 1;
    }
    span:nth-child(2) {
      font-weight: 400;
    }
  }
}
