.zoom-area-images {
  position: absolute;
  width: 100%;
  overflow: hidden;

  &__container {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    clear: both;

    img {
      width: 100%;
      float: left;
      margin: 0;
      padding: 0;
    }
  }
}

.current-image-outline {
  position: absolute;
  width: 100%;
  border: 2px solid $theme-primary;
}
