.privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-blend-mode: color;
  background-size: cover;

  &__box {
    background: $ms-color-gray10;
    box-shadow: $ms-depth-shadow-64;
    border-radius: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    max-height: 90vh;
    max-width: 95vw;

    @include breakpoint($bp-s) {
      padding: 68px 68px 36px;
    }

    @include breakpoint($bp-m-l) {
      padding: 100px 100px 44px;
    }
  }

  &__logo {
    align-self: center;
    @include breakpoint($bp-s) {
      width: 156px;
    }

    @include breakpoint($bp-m) {
      width: 212px;
    }

    @include breakpoint($bp-l) {
      width: 246px;
    }
  }

  .text-container {
    max-width: 700px;
    text-align: left;
    font-weight: 600;
    color: $ms-color-gray160;

    @include breakpoint($bp-s) {
      font-size: 14px;
      line-height: 20px;
    }

    @include breakpoint($bp-m) {
      font-size: 16px;
      line-height: 22px;
    }

    @include breakpoint($bp-l) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .partner-logo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    img {
      @include breakpoint($bp-s) {
        height: 45px;
      }

      @include breakpoint($bp-m) {
        height: 50px;
      }

      @include breakpoint($bp-l) {
        height: 60px;
      }
    }
  }
}
