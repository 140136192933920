.viewer-toolbar {
  position: absolute;
  background: white;
  bottom: $scaleBarWidth + 30px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 150ms linear;
  border-radius: 2px;
  overflow: hidden;
  z-index: 20;

  *:focus {
    outline: none;
  }

  &--visible {
    opacity: 1;
  }
}

.cuttings-navigation-button {
  &:hover &__depth,
  &:focus &__depth {
    display: block;
    margin-top: 4px;
  }

  &__depth {
    display: none;
  }
}

.image-adjustments-container {
  margin: 0 8px;
  width: 220px;
}
