.plot-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  padding: 8px;
  background-color: $ms-color-gray10;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  pointer-events: none;
  box-shadow: $ms-depth-shadow-16;
  white-space: nowrap;

  &__stratigraphy {
    border-bottom: 1px solid $ms-color-gray40;
    margin-bottom: 4px;
    padding-bottom: 4px;
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      span:first-child {
        width: 16px;
        height: 16px;
        border: 1px solid $ms-color-gray130;
        margin-right: 4px;
      }
    }
  }

  &__heading {
    font-weight: 600;
  }
  &__row {
    display: flex;
    flex-flow: row nowrap;
    span:nth-child(1) {
      flex-grow: 1;
    }
  }
  &__beak {
    position: absolute;
    width: 0;
    // Position the beak at the bottom of the tooltip by default
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid $ms-color-gray10;
    border-bottom: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    &--top {
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid $ms-color-gray10;
      border-top: 0;
      top: -16px;
    }
  }
}
