.dropdown-deactivator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--fixed {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
}
