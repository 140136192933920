.scalebars {
  @include no-select;

  path,
  line {
    stroke: $ms-color-gray160;
    stroke-width: 1;
  }
  text {
    color: $ms-color-gray160;
  }
}
