.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-blend-mode: color;
  background-size: cover;

  &__box {
    background: $ms-color-gray10;
    box-shadow: $ms-depth-shadow-64;
    border-radius: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 95vw;

    @include breakpoint($bp-s) {
      padding: 68px 68px 36px;
      height: 544px;
    }

    @include breakpoint($bp-m) {
      padding: 100px 100px 44px;
      height: 711px;
    }

    @include breakpoint($bp-l) {
      padding: 124px 124px 52px;
      height: 847px;
    }
  }

  &__logo {
    align-self: center;
    @include breakpoint($bp-s) {
      width: 156px;
    }

    @include breakpoint($bp-m) {
      width: 212px;
    }

    @include breakpoint($bp-l) {
      width: 246px;
    }
  }

  h1 {
    align-self: center;
    margin-top: auto;
    color: $ms-color-gray160;

    @include breakpoint($bp-s) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }

    @include breakpoint($bp-m) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    @include breakpoint($bp-l) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .text-container {
    max-width: 400px;
    text-align: center;
    font-weight: 600;
    color: $ms-color-gray160;

    @include breakpoint($bp-s) {
      font-size: 14px;
      line-height: 20px;
      margin-top: 24px;
      margin-bottom: 64px;
    }

    @include breakpoint($bp-m) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 32px;
      margin-bottom: 80px;
    }

    @include breakpoint($bp-l) {
      font-size: 18px;
      line-height: 24px;
      margin-top: 40px;
      margin-bottom: 96px;
    }
  }

  .button-container {
    align-self: center;
  }

  .partner-logo-container {
    display: flex;
    justify-content: space-between;

    img {
      @include breakpoint($bp-s) {
        height: 45px;
      }

      @include breakpoint($bp-m) {
        height: 50px;
      }

      @include breakpoint($bp-l) {
        height: 60px;
      }
    }
  }
}
