.wellbore-tops {
  &__chart-root {
    overflow: hidden;
  }

  .formation-rect {
    stroke: $ms-color-gray130;
    stroke-width: 1px;
    cursor: pointer;
  }
}
