#map-view {
  background: $ms-color-gray10;

  text {
    user-select: none;
    text-anchor: middle;
    dominant-baseline: middle;
  }
}

.land-path {
  fill: $ms-color-gray40;
}
.block-path,
.quad-path {
  fill: transparent;
  stroke: $ms-color-gray60;
}

.quad-path {
  stroke-width: 30px;
}
.block-path {
  stroke-width: 5px;
}

.field-path {
  stroke-width: 1px;
  stroke: $ms-color-gray60;
  fill: $ms-color-gray40;
  fill-opacity: 0.5;
}

.block-label,
.quad-label,
.field-label {
  fill: $ms-color-gray120;
}

.quad-label {
  font-size: 300px;
}

.block-label {
  font-size: 40px;
}

.field-label {
  font-size: 15px;
}

#wellbore-container {
  .wellbore {
    &__point {
      cursor: pointer;
      fill: $theme-primary;
      opacity: 0.8;
      &--filtered {
        fill: $ms-color-gray80;
      }
    }

    &__label {
      cursor: pointer;
      fill: $ms-color-gray190;
    }

    &__icon {
      pointer-events: none;
    }

    &:hover {
      .wellbore__point {
        fill: $theme-tertiary;
        &--filtered {
          fill: $ms-color-gray90;
        }
      }
      .wellbor__label {
        fill: $theme-tertiary;
      }
    }
    &--active {
      .wellbore__point {
        fill: $ms-color-orange !important;
        stroke: $ms-color-orange;
      }
      .wellbore__label {
        fill: $ms-color-orange !important;
      }
    }
  }
}

#non-rwi-wellbore-container {
  .wellbore {
    &__point {
      fill: $ms-color-gray80;
      opacity: 0.8;
      cursor: not-allowed;
    }

    &__label {
      fill: $ms-color-gray120;
      cursor: not-allowed;
    }

    &:hover {
      .wellbore__point {
        fill: $ms-color-gray90;
      }
      .wellbore__label {
        fill: $ms-color-gray130;
      }
    }
  }
}

.wellbore-traversal-controller {
  color: $ms-color-gray160;
  white-space: nowrap;
  &__text {
    margin-right: 52px;
  }

  &__ratio {
    color: $ms-color-gray160;
    margin-right: 16px;
  }

  &__border {
    height: 48px;
    width: 1px;
    background: $ms-color-gray40;
    margin-right: 8px !important;
    margin-left: 16px !important;
  }
}
