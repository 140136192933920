.wellbore-symbols {
  &__chart-root {
    overflow: hidden;
  }
  &__casing-shoe {
    fill: $ms-color-gray130;
    stroke: $ms-color-gray150;
    &:hover {
      fill: $ms-color-gray120;
    }
  }
}
