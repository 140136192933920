.stage {
  .unit-of-measurement {
    font-size: 10px;
    text-anchor: middle;
  }

  #openSeaDragon,
  #openSeaDragon-gridView {
    position: absolute;
    background-color: #e5e5e5;
    border: 1px solid darkgray;
    transition: opacity 200ms linear;
    opacity: 0;
    z-index: 0;

    &.viewer--visible {
      opacity: 1;
      z-index: 1;
    }
  }

  .overlay {
    z-index: 1;
    cursor: pointer;
    @include no-select;
    color: rgba(255, 255, 255, 0);
    position: relative;
  }

  .overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
  }
}

.viewer-tools-menu {
  position: absolute;
  top: 130px;
  left: 42px;
  z-index: 10;
  background: $ms-color-gray30;
  box-shadow: $ms-depth-shadow-16;
  border-radius: 2px;
  overflow: hidden;
}
