// https://github.com/OfficeDev/office-ui-fabric-core
@import "./../node_modules/office-ui-fabric-core/dist/sass/References";
@import "./../node_modules/office-ui-fabric-core/dist/sass/Color.scss";

@import "./variables.scss";
@import "./components/manifest.scss";
@import "./pages/manifest.scss";

body {
  margin: 0;
  font-family: $default-font-family; // -apple-system, BlinkMacSystemFont,
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  min-height: 700px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 2px;
}

.hide {
  display: none;
}

.no-padding {
  padding: 0;
}

.hidden-ref-element {
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
}

.flex-grow {
  flex-grow: 1;
}
