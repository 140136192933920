$scaleBarWidth: 21px;

$bp-s: "max-width: 1365px";
$bp-s-m: "max-width: 1919px";
$bp-m: "min-width: 1366px) and (max-width: 1919px";
$bp-m-l: "min-width: 1366px";
$bp-l: "min-width: 1920px";

@mixin breakpoint($breakpoint) {
  @media ($breakpoint) {
    @content;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

$default-font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$theme-primary: #61acbb;
$theme-lighter-alt: #d4e9ed;
$theme-lighter: #b7d9e1;
$theme-light: #8dc3ce;
$theme-tertiary: #3d808f;
$theme-dark-alt: #377481;
$theme-dark: #2b5a64;
$theme-darker: #254e56;

$theme-error-background: #fde7e9;
$theme-success-background: #dff6dd;
$theme-severe-warning-background: #fed9cc;
$theme-warning-background: #fff4ce;
$theme-error: #a80000;
$theme-success: #107c10;
$theme-severe-warning: #d83b01;
$theme-warning: #797775;
