.range-finder {
  &__backdrops {
    display: flex;
    position: absolute;
    flex-flow: row nowrap;
    flex-direction: row-reverse;
    overflow: hidden;
    @include no-select;
    width: 100%;
  }
  &__backdrop {
    position: relative;
    min-width: 0;
    min-height: 0;
    overflow: hidden;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
    img {
      width: 100%;
      min-width: 60px;
      height: 100%;
      user-select: none;
    }
    .reference-log {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__slider {
    position: relative;
  }

  &__thumb {
    position: absolute;
    height: 16px;
    width: 28px;
    left: 50%;
    transform: translate(-50%, -8px);
    box-shadow: $ms-depth-shadow-8;

    & > * {
      pointer-events: none;
    }
  }

  &__current {
    display: none;
    position: absolute;
    z-index: 20;
    opacity: 0;
    left: 0;
    transform: translateX(calc(-100% - 5px));
    height: 46px;
    padding: 0 8px;
    font-size: 24px;
    line-height: 46px;
    background-color: $ms-color-gray30;
    user-select: none;
    border-radius: 2px;
    transition: opacity 150ms linear;
    box-shadow: $ms-depth-shadow-16;

    &::after {
      position: absolute;
      right: 0;
      top: 15px;
      z-index: -1;
      height: 16px;
      width: 16px;
      content: "";
      transform: translateX(50%) rotate(45deg);
      background: $ms-color-gray30;
    }
  }

  .zoom-area-blurred {
    position: absolute;
    width: 100%;
    background: $ms-color-gray30;
    opacity: 0.5;
  }
}

.zoom-area {
  position: absolute;
  width: 100%;
  cursor: pointer;

  &__thumb {
    position: absolute;
    background: $theme-tertiary;
    width: 100%;
    pointer-events: all;
    $height: 3px;
    height: $height;
    z-index: 1;

    &:hover {
      cursor: ns-resize;
    }

    svg {
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
      & > * {
        pointer-events: none; // Pass pointerEvent.target to the svg
      }
    }

    &--top {
      top: calc(-#{$height} / 2);
    }

    &--bottom {
      bottom: calc(-#{$height} / 2);
    }
  }

  &__clear-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.visible {
  opacity: 1;
  display: block;
}
