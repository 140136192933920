@import "../../variables.scss";

.color-legend-container {
  position: absolute;
  top: 150px;
  left: 100px;
  min-width: 0px;
  min-height: 0px;
  overflow: hidden;
  box-shadow: $ms-depth-shadow-16;
  transition: max-height 0.7s ease;
  background-color: white;

  &.expanded {
    max-height: 850px;
  }

  &.minimized {
    max-height: 35px;

    @include breakpoint($bp-l) {
      max-height: 40px;
    }

    .ms-Modal-scrollableContent {
      overflow-y: hidden;
    }
  }

  &__header {
    height: 35px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 12px 12px 14px 12px;
    font-size: 12px;
    background-color: $ms-color-gray30;

    @include breakpoint($bp-m) {
      font-size: 14px;
    }

    @include breakpoint($bp-l) {
      font-size: 16px;
      height: 40px;
    }
  }

  &__image {
    @include breakpoint($bp-s) {
      width: 135px;
    }

    @include breakpoint($bp-m) {
      width: 155px;
    }

    @include breakpoint($bp-l) {
      width: 185px;
    }
  }
}
