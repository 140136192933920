.range-finder__sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: $ms-color-gray30;
  z-index: 100;
}

.help-tab {
  display: none;
  width: 600px;
  height: 575px;
  padding: 24px;
  overflow-y: auto;

  &--visible {
    display: block;
  }

  h2 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .shortcuts-table {
    margin: 0;
    border-collapse: collapse;
    margin-top: 24px;
    width: 100%;
    tr {
      width: 100%;
    }
    th {
      margin: 0;
      padding: 0;
      padding-bottom: 12px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }
    td {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      &:first-child {
        width: 50%;
      }
    }
  }
}
