.mini-map {
  position: absolute;
  background-clip: content-box;
  bottom: $scaleBarWidth + 30px;
  // z-index is 10 or 60 depending on whether MapView is open
  background-color: $ms-color-gray30;
  box-shadow: $ms-depth-shadow-8;

  filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)),
    drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13));
  border-radius: 2px;

  &__depth {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 48px;
    font-size: 24px;
    height: 48px;

    &--outline {
      border: 2px solid $theme-primary;
    }
  }

  &__stratigraphy-container {
    position: relative;
  }

  &__stratigraphy {
    position: absolute;
    width: 100%;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    z-index: 3;
    background: $ms-color-gray40;
    color: $ms-color-gray160;

    &--group {
      top: 0;
    }

    &--formation {
      bottom: 0;
    }
  }

  /* div element containing the OpenSeadragon navigator */
  &__container {
    background-color: $ms-color-white;
    z-index: 2;
    @media (max-width: 1365px) {
      width: 192px;
      height: 100px;
    }

    @media (min-width: 1366px) and (max-width: 1919px) {
      width: 220px;
      height: 144px;
    }

    @media (min-width: 1920px) {
      width: 268px;
      height: 192px;
    }

    .preview-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: white;
      @include no-select;
    }
    .preview-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      // width and height set with javascript
      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__toolbar {
    height: 32px;

    .border {
      height: 32px;
      border-left: 1px solid #e1dfdd;
      margin-right: 8px;

      &--narrow {
        margin-right: 0px;
      }
    }

    input {
      width: 35px;
      height: 32px;
      margin: 0;
      margin-left: 6px;
      background-color: #edebe9;
      border: 0;
      outline: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .unit {
      margin-right: 8px;
      @include no-select;
    }
  }
}
