.track {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: $scaleBarWidth;
  bottom: $scaleBarWidth;
  @include no-select;
  height: calc(100vh - 2 * #{$scaleBarWidth});
  background: $ms-color-gray40;

  &__header,
  &__footer {
    height: 27px;
    padding: 6px;
    background-color: $ms-color-gray30;
    font-size: 12px;
    font-weight: 600;
    color: $ms-color-gray130;
    text-align: center;
    user-select: none;
  }
  &__content {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    background: transparent;
  }

  &__resize-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    left: 0;
    transform: translateX(-50%);
    cursor: col-resize;
  }
}
