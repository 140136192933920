.sidebar-button::before {
  content: "";
}

.pivot--hide-selection {
  .ms-Pivot-link.is-selected::before {
    display: none;
  }
}

.ms-Fabric--isFocusVisible .ms-Pivot-link:focus {
  outline: 1px solid $theme-primary;
}
.ms-ContextualMenu-Callout {
  .ms-Callout-main {
    background-color: $ms-color-gray30;
  }
}
